.circle-pic{
    width: 250px;
    position: relative;
}
@keyframes slidee {
    
    0%{
        transform: translateX(-400px);
    }
    40%{
        transform: translateX(0px);
    }
    60%{
        transform: translateX(0px);
    }
    100%{
        transform: translateX(400px);
    }
}
.slide{
    animation: slidee 3000ms infinite ease-in-out;
}
@media screen and ( max-width: 750px){
    .circle-pic{
        width: 150px;
        position: relative;
    }
}