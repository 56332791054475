.hero{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.right{
    width: 50%;
    font-size: 30px;
    padding-left: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.btn{
    width: 160px;
    height: 40px;
    margin-top: 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    transition: 0.5s;
}
.btn:hover{
    background-color: rgb(10, 0, 104);
}
.left{
    padding-left: 20px;
}
.circle1{
    width: 400px;
    height: 400px;
    border: 5px dashed rgb(255, 123, 0);
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 80px;

}
.circle2{
    width: 350px;
    height: 350px;
    border: 5px solid rgb(224, 0, 0);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    background-size: cover;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
@media screen and ( max-width: 750px){

    .hero{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .hero-main{
        width: 100%;
        height: 100vh;
    }

    .right{
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 20px;
        padding-left: 0;
    }
    .left{
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }
    
   
    .circle1{
        width: 250px;
        height: 250px;
        border: 5px dashed rgb(255, 123, 0);
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-top: 0;
    
    }
    .circle2{
        width: 210px;
        height: 210px;
        border: 5px solid rgb(224, 0, 0);
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        background-size: cover;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }
}