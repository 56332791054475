*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.main-content{
    width: 100%;
    height: 260px;
    display: flex;
    margin-bottom: 1px;
    align-items: center;
    white-space: nowrap; 
    overflow: hidden;
    border-bottom: 2px solid red;
    border-top: 2px solid red;
}
.product{
    display: inline-flex;
    align-items: center;
    gap: 10px;
    animation: 15s slide infinite linear;
   
}

@keyframes slide{
    from{
        transform: translatex(-100%);
    }
    to{
        transform: translatex(0%);
    }
    
}
.product img{
    width: 200px;
}
@media screen and ( max-width: 750px){
    .main-content{
        width: 100%;
        height: 150px;
        display: flex;
        margin-bottom: 1px;
        align-items: center;
        white-space: nowrap; 
        overflow: hidden;
        border-bottom: 2px solid red;
        border-top: 2px solid red;
    }
}