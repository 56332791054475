.photo-div{
    width: 300px;
    height: 300px;
    overflow: hidden;
}
.contact-pic{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.text-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.h2-contact{
    margin: 120px 0px;
}
@media screen and ( max-width: 750px){
    .contact-main{
        width: 100%;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .h2-contact{
        margin: 0px 0px;
    }
    .contact-second{
     display: flex;
     flex-direction: column;
     height: 100vh;
     margin: 0;
     padding: 0;

    }
    .left{
        width: 300px;
        height: 300px;
    }
    .right{
        width: 300px;
        height: 300px;
        align-items: center;
        justify-content: center;
    }
    .contact-pic{
        width: 200px;
        height: 200px;
        margin-top: 20px;
    }
    .photo-div{
        width: 100%;
        height: 50vh;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .text-div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}