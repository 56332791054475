.nav{
    color: rgb(255, 255, 255);
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #273c75;;
    box-shadow: 1px 1px 10px rgb(182, 182, 182);
    position: fixed;
    z-index: 999;
    padding-right: 50px;
}
.logo{
    width: 200px;
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: center;
    gap: 1PX;
}
.logo p{
    font-size: 12px;
    margin-top: -6px;
    font-weight: 500;
}
.logo h4{
    color: rgb(255, 255, 255);

}
.list{
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    margin-right: 60px;
}
.list li{
    display: inline-block;
    justify-content: center;
    text-decoration: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.5s;
}

.list li:hover{
    color: rgb(255, 0, 0);
}
.nav-btn{
    display: none;
    flex-direction: column;
    gap: 8px;
    align-items: end;
    cursor: pointer;
    
}

.sidebar{
    position: absolute;
    z-index: 1000;
}
@media screen and ( max-width: 750px){
    .nav{
        width: 100%;
        padding: 0;
    }
    .list{
    display: none;
    
    }
    .nav-btn{
        display: flex;
        padding-right: 0px;
    }
}