.service-main{
    max-width: 1100px;
    height: 300vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.tire{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.tire-section-pic{
    
   width: 400px;
   margin: 10px 10px;
}
.service-text{
    display:"flex";
    flex-direction:column;
    justify-content:"center";
    align-items:"center" ;
    padding:"0 20px";
}
@media screen and ( max-width: 750px){
    .service-main{
        max-width: 100%;
        height: 300vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .tire-pic{
        width: 100%;
        display: flex;
        flex-direction: column;

    }
    .tire-section-pic{
        width: 200px;
    }
    .service-text{
        display:"flex";
        flex-direction:column;
        justify-content:"center";
        align-items:"center" ;
        padding:"0 20px";
        font-size: 14px;
    }
}