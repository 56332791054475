.about-section{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #2980b9;
    color: rgb(255, 255, 255);
    padding-bottom: 100px;
}
.about-title{
    font-size: 30px;
}
.tag-name{
    margin-top: 50px;
    color: rgb(255, 255, 255);
}
.main-contentt{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: end;
}
@media screen and ( max-width: 750px){
    .about-section{
        width: 100%;
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0px;
        background-color: #2980b9;
        color: rgb(255, 255, 255);
        padding: 10px 15px;
       
    }
    .tag-name{
        margin-top: 10px;
        color: rgb(255, 255, 255);
        font-size: 20px;
    }
    .main-contentt{
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: start;
        align-items: start;
        font-size: 14px;
        direction: rtl;
    }
    .about-title{
        font-size: 20px;
    }
}