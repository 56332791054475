.click-items{
    display: none;
    flex-direction: column;
    gap: 8px;
    align-items: end;
    cursor: pointer;
    z-index: 1000;
    margin-top: 12px;
    position: fixed;
    right: 20px;
}
@media screen and ( max-width: 750px){
    .click-items{
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: end;
        cursor: pointer;
        z-index: 1000;
        margin-top: 12px;
        position: fixed;
        right: 20px;
    }
}